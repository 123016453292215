import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../index';

export const AlertDialogMobile = ({
  open,
  handleClose,
  title,
  children,
  cancelButtonText,
  confirmButtonText,
  confirmDisabled,
  cancelHidden,
  confirmHidden,
  handleCancel,
  handleConfirm,
  onBackdropClick,
  modalClasses,
  titleClasses,
  contentClasses,
  actionsClasses,
  actionButtonsClasses,
  type,
  ...rest
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      onBackdropClick={onBackdropClick || handleCancel}
      classes={modalClasses}
      {...rest}
    >
      <DialogTitle classes={titleClasses}>{title || ''}</DialogTitle>
      <DialogContent classes={contentClasses}>
        {children}
      </DialogContent>
      <DialogActions classes={actionsClasses}>
        {
          cancelHidden
          ? null
          : <Button
              classes={actionButtonsClasses}
              onClick={handleCancel}
              color='secondary'
            >
              {cancelButtonText}
            </Button>
        }
        {
          confirmHidden
          ? null
          : <Button
              classes={actionButtonsClasses}
              onClick={handleConfirm}
              color='secondary'
              type={type}
              disabled={confirmDisabled}
              autoFocus={type !== 'submit'}
            >
              {confirmButtonText}
            </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

AlertDialogMobile.defaultProps = {
  handleClose: () => {},
  title: '',
  cancelButtonText: '',
  confirmButtonText: '',
  confirmDisabled: false,
  handleCancel: () => {},
  handleConfirm: () => {},
  onBackdropClick: () => {},
  modalClasses: {},
  titleClasses: {},
  contentClasses: {},
  actionsClasses: {},
  actionButtonsClasses: {},
  cancelHidden: false,
  confirmHidden: false,
};

AlertDialogMobile.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node.isRequired,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  onBackdropClick: PropTypes.func,
  modalClasses: PropTypes.object,
  titleClasses: PropTypes.object,
  contentClasses: PropTypes.object,
  actionsClasses: PropTypes.object,
  actionButtonsClasses: PropTypes.object,
  cancelHidden: PropTypes.bool,
  confirmHidden: PropTypes.bool,
};

export const AlertDialog = withMobileDialog({breakpoint: 'xs'})(AlertDialogMobile);
